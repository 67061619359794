import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import './App.css';
import detectBrowserLanguage from 'detect-browser-language'    
import ReactGA from 'react-ga';



class site extends Component{
    componentDidMount(){
        document.title = "Nekojima: Idle Cat Collector - Aozame Interactive"
        ReactGA.initialize('UA-168593217-2');
        ReactGA.pageview('Overview');
    }

    constructor(props){
        super(props);
        this.state = {
            currentLanguage: '',
            currentSection: 1,
        }
        this.setLanguage = this.setLanguage.bind(this)
        this.setSection = this.setSection.bind(this)
        //"en", "jp(ja)", "fr", "de", "ru", "cn(zh)", "vn(vi)", "kr(ko)"
        if (detectBrowserLanguage().slice(0,2) === "ja" && this.state.currentLanguage === ""){
            this.state.currentLanguage = "jp";
            ReactGA.event({
              category: 'Language',
              action: 'Default Language',
              value: "jp"
            });
        } else if (this.state.currentLanguage === "") {
            this.state.currentLanguage = "en";
            ReactGA.event({
              category: 'Language',
              action: 'Default Language',
              value: "en"
            });
        }
    }
    
    setSection(section){
        if (section === 2){
            ReactGA.outboundLink(
              {
                label: 'Nekojima Facebook'
              },
                function () {
                console.log('redirect here');
              }
            )
            var win = window.open("https://facebook.com/nekojimagame", '_blank');
            win.focus();
        } else if (section === 3){
            ReactGA.outboundLink(
              {
                label: 'Nekojima Help'
              },
                function () {
                console.log('redirect here');
              }
            )
            var win = window.open("https://nekojima.aozame.com/help", '_blank');
            win.focus();
        } else {
            this.setState ({
            currentSection: section
            });
            window.scrollTo(0, 0);
        }

    }

    setLanguage(language){
        this.setState ({
            currentLanguage: language
        });
        if (language === "en"){
            ReactGA.event({
              category: 'Language',
              action: 'Set Language',
              value: "en"
            });
        } else if (language === "jp"){
            ReactGA.event({
              category: 'Language',
              action: 'Set Language',
              value: "jp"
            });
        }
    }
    
    render() {
        //Localized Strings
        let title = {en: "Nekojima: Idle Cat Collector - Aozame Interactive",
                     jp: "猫島：猫放置系ゲーム - アオザメインタラクティブ"};
        let contentShown;      
        if (this.state.currentSection === 1) {
            contentShown = <Overview
            currentLanguage = {this.state.currentLanguage}
            setSection = {this.setSection}/>;
            ReactGA.pageview('Overview');
        } else if (this.state.currentSection === 2) {
            var win = window.open("http://google.com", '_blank');
            win.focus();
        } else if (this.state.currentSection === 3) {
            contentShown = <Contact
            currentLanguage = {this.state.currentLanguage}
            setSection = {this.setSection}/>;
            ReactGA.pageview('Help');
        } 
        
        return (
            <div className="main">
                <MetaTags>
                    <title>{title[this.state.currentLanguage]}</title>
                    <meta name="apple-mobile-web-app-capable" content="yes"/>
                    <meta name="apple-mobile-web-app-title" content="Nekojima - Aozame Interactive" />   
                    <meta name="application-name" content="Nekojima - Aozame Interactive" />
                    <meta name="description" content="The official website of Nekojima by Aozame Interactive" />
                    <meta name="viewport" content="width=device-width, initial-scale=1, viewport-fit=cover" />
                </MetaTags>
                <Header
                    currentLanguage = {this.state.currentLanguage}
                    setLanguage = {this.setLanguage}
                />
                {contentShown}
                <Navigation
                    language = {this.state.currentLanguage}
                    setSection = {this.setSection}
                />
            </div>
        );
    }
}

class Header extends Component{
    constructor(props){
        super(props);
        this.state = {
            value: ''
        }
    }

    render() {
        return (
            <div id="header">
                <CountryButton
                    currentLanguage = {this.props.currentLanguage}
                    setLanguage = {this.props.setLanguage}
                />
            </div>
        );
    }
}

class Footer extends Component{
    constructor(props){
        super(props);
        this.state = {
            value: ''
        }
    }

    render() {
        let footerText = {en: "Privacy Policy",
                          jp: "プライバシーポリシー"};
        let footerText2 = {en: "Terms of Use",
                           jp: "利用規約"};
        let footerLink = {en: "https://aozame.com/privacy.html",
                          jp: "https://aozame.com/privacy_jp.html"};
        let footerLink2 = {en: "https://aozame.com/tou.html",
                           jp: "https://aozame.com/tou_jp.html"};
        return (
            <div id="footer">
                <span className="copyright">&copy;</span>2020 Aozame Interactive<br/>
                <ReactGA.OutboundLink
                    eventLabel="View Privacy Policy"
                    to={footerLink[this.props.currentLanguage]}
                >
                {footerText[this.props.currentLanguage]}
                </ReactGA.OutboundLink>・ 
                <ReactGA.OutboundLink
                    eventLabel="View ToU"
                    to={footerLink2[this.props.currentLanguage]}
                >
                {footerText2[this.props.currentLanguage]}
                </ReactGA.OutboundLink>
            </div>
        );
    }
}

class Overview extends Component{
    constructor(props){
        super(props);
        this.state = {}
        this.setSection = this.setSection.bind(this);
    }

    setSection(){
        this.props.setSection(1);
    }

    render() {      
        const downloadApple = require('./components/img/download/apple_' +this.props.currentLanguage +'.png')
        const downloadAndroid = require('./components/img/download/android_' +this.props.currentLanguage +'.png')
        let title = {en: "Welcome to Nekojima!",
                     jp: "ようこそへ猫島！"};
        let content = {en: "Have you ever wanted to be surrounded by countless adorable cats?<br><br>If so, Nekojima will help make your dream come true!<br><br>Inspired by Tashirojima (a real-world Cat Island in Japan), the quiet island of Nekojima needs your help to become the PURRFECT tourist destination for cat lovers.<br><br>With your help, the quiet and tiny island of Nekojima will soon fill with countless cute cats and adorable kittens as it attracts cat-lovers from around the world. There's no time to PAWS on your journey to becoming a world-renowned cat lovers paradise. You'll want to tap tap tap as quickly as possible in this clicker style game to earn money, upgrade your island, and collect ALL THE CATS!<br><br>Manage your boats to ferry new visitors to your island and earn a passive income even while offline.<br><br>What are you waiting for? Your very own cat paradise awaits.<br><br>Play MEOW!<br>(=^･ω･^=)",
                       jp: "たくさん猫が欲しかったことがありますか？<br><br>この島は猫愛好家の夢です。<br><br>猫島は田代島という日本の中に本物の猫の島を基ずきます。<br><br>この島はあなたの助けが必要です。今、小さくて、人気じゃない島です。あなたの助けながら、この島はたくさん猫愛好家がある世界有名島になれます！<br><br>夢の中に毎日数千お客様は島に来ます。未来に、私たちの夢を実現させましょう。<br><br>タプして、お金持ちになって、名所を建てます。もっと名所を作りながら、もっとお金を得ます。<br><br>今プレイをしてくれてください。たくさん可愛い猫が待っていますよ！<br>(=^･ω･^=)"};
        let title2 = {en: "Game Features",
                     jp: "ゲームの特徴"};
        
        let featureTitle1 = {en: "Build Your Island",
                         jp: "猫の島を建てる"};
        let featureDetails1 = {en: "Attract visitors to your quiet little island by expanding and building exciting new attractions!",
                        jp: "静かな猫島へお客様を引き付けるのために数の刺激的アトラクションを建てます。"};
        let featureTitle2 = {en: "Collect Cute Cats",
                         jp: "可愛い猫を集める"};
        let featureDetails2 = {en: "Level up your island and prestige to earn Fish, which can be used to unlock new cats at random.",
                        jp: "島をレベルアップしてリセットすれば魚を増します。猫ガチャで魚を使ったら、新しい猫をランダムもらいます。"};
        let featureTitle3 = {en: "Unlock Cat Bios",
                         jp: "伝記をアンロック"};
        let featureDetails3 = {en: "Level up your cats to increase their popularity, as well as unlock additional information on their cat bio page.",
                        jp: "島の人気を上げると猫の伝記で猫の情報をアンロックのために猫たちをレベルアップします。"};
        let featureTitle4 = {en: "Join Seasonal Events",
                         jp: "イベントを参加する"};
        let featureDetails4 = {en: "Take part in limited-time events, complete tasks, and earn special cats only available during the event.",
                        jp: "特別限定猫をもらうのために期間限定のイベントに参加して色々ミッションを完了します。"};




        
        const mainLogo = require('./components/img/logos/' +this.props.currentLanguage +'.png')
        const heroImage = require('./components/img/hero/' +this.props.currentLanguage +'.png')
        const featureImage1 = require('./components/img/features/1_' +this.props.currentLanguage +'.png')
        
        return (
            <div id="overview">
                <div className="content">
                    <div id="logo">
                        <button onClick = {this.setSection}>
                            <img src={mainLogo} className="main-logo" alt="Nekojima"/>
                        </button>
                    </div>
                    <div id="hero-image">
                        <center>
                            <img src={heroImage} className="hero-image"/>
                        </center>
                    </div>
                    <div id="downloads">
                    <ReactGA.OutboundLink
                        eventLabel="Download - iOS"
                        to="https://apps.apple.com/app/nekojima-idle-cat-collector/id1271776170"
                    >
                    <img src={downloadApple} className="download-button" alt="Aozame Interactive" width="auto" height="50px"/>
                    </ReactGA.OutboundLink>
                    <ReactGA.OutboundLink
                        eventLabel="Download - Android"
                        to="https://play.google.com/store/apps/details?id=com.GrantWall.Nekojima"
                    >
                    <img src={downloadAndroid} className="download-button" alt="Aozame Interactive" width="auto" height="50px"/>
                    </ReactGA.OutboundLink>
                    </div>
                    <div className="title-holder">
                        <div className="title">
                            {title[this.props.currentLanguage]}
                        </div>
                    </div>
                    <div id="overview-box">
                       <div className="about-text" dangerouslySetInnerHTML={{__html: content[this.props.currentLanguage]}}></div>
                    </div>
                    <div className="title-holder flip-title">
                        <div className="title">
                            {title2[this.props.currentLanguage]}
                        </div>
                    </div>
                    <div id="overview-box">
                        <div id="features-box">
                        <FeatureItem
                            currentLanguage = {this.props.currentLanguage}
                            name = {featureTitle1}
                            description = {featureDetails1}
                            float = "left"
                            id = "1"
                        />
                        <FeatureItem
                            currentLanguage = {this.props.currentLanguage}
                            name = {featureTitle2}
                            description = {featureDetails2}
                            float = "right"
                            id = "2"
                        />
                        <FeatureItem
                            currentLanguage = {this.props.currentLanguage}
                            name = {featureTitle3}
                            description = {featureDetails3}
                            float = "left"
                            id = "3"
                        />
                       <FeatureItem
                            currentLanguage = {this.props.currentLanguage}
                            name = {featureTitle4}
                            description = {featureDetails4}
                            float = "right"
                            id = "4"
                        />
                        </div>
            
                    </div>

                </div>
                <Footer
                    currentLanguage = {this.props.currentLanguage}
                />
            </div>
        );
    }
}

class FeatureItem extends Component{
    constructor(props){
        super(props);
        this.state = {}
    }
    
    render() {       
        const featureImage = require('./components/img/features/'+this.props.id+ '_' + this.props.currentLanguage +'.png')
    
        let avatarFloat;
        let textFloat;
        if (this.props.float === "right"){
            avatarFloat = "feature-image image-right"
            textFloat = "feature-text text-right"
        } else {
            avatarFloat = "feature-image image-left"
            textFloat = "feature-text text-left"
        }
        
        const aboutClass = "feature-block feature-" + this.props.id
        
        return (
            <div className={aboutClass}>
                <div className={avatarFloat}>
                    <div className="feature_png">
                        <img src={featureImage} className="feature_image" alt="Avatar"/>
                    </div>
                </div>
                <div className={textFloat}>
                    <div className="feature-vertical-center">
                        <div className="feature-title">
                            {this.props.name[this.props.currentLanguage]}
                        </div>
                        <div className="feature-description" dangerouslySetInnerHTML={{__html: this.props.description[this.props.currentLanguage]}}>
                        </div>
                    </div>
                </div>         
            </div>
        );
    }
}

class Contact extends Component{
    constructor(props){
        super(props);
        this.state = {}
        this.setSection = this.setSection.bind(this);
    }

    setSection(){
        this.props.setSection(1);
    }

    render() {      
        let title = {en: "contact",
                     jp: "お問い合わせ"};
        let subtitle1 = {en: "social",
                     jp: "ＳＮＳ"};
        let subtitle2 = {en: "press inquiries",
                     jp: "報道機関お問い合わせ"};
        let subtitle3 = {en: "need help?",
                     jp: "サポート"};
        let content = {en: "Looking to get in touch?<br><br>Reach out via our social channels, or drop us a message using the emails below. ",
                       jp: "連絡を取り合いたい？<br>SNSで連絡するやメールを送ってくれてください。"};

        let mailPress = {en: 'mailto:press@aozame.com?subject=Press Inquiry',
                     jp: 'mailto:press@aozame.com?subject=報道機関お問い合わせ'}
        let mailHelp = {en: 'mailto:help@aozame.com?subject=Help Request',
                     jp: 'mailto:help@aozame.com?subject=サポート リクエスト'}
        
        const mainLogo = require('./components/img/logos/' +this.props.currentLanguage +'.png')
        
        return (
            <div id="contact">
                <div id="content" className="content">
                    <div id="logo">
                        <button onClick = {this.setSection}>
                            <img src={mainLogo} className="main-logo" alt="Aozame Interactive"/>
                        </button>
                    </div>
                    <div className="title">
                        {title[this.props.currentLanguage]}
                    </div>
                    <div id="contact-box">
                       <div className="about-text" dangerouslySetInnerHTML={{__html: content[this.props.currentLanguage]}}></div>
                    </div>
                    <div id="contact-content">
                        <div className="subtitle sub-1">
                            {subtitle1[this.props.currentLanguage]}
                        </div>
                        <div id="social-media">
                            <ContactSocial
                                type="facebook"
                                link="https://www.facebook.com/aozameinteractive"
                            />
                            <ContactSocial
                                type="twitter"
                                link="https://twitter.com/aozamegames"
                            />
                            <ContactSocial
                                type="instagram"
                                link="https://instagram.com/aozameinteractive"
                            />
                            <ContactSocial
                                type="linkedin"
                                link="https://linkedin.com/company/aozame"
                            />
                        </div>
                        <div className="subtitle">
                            {subtitle2[this.props.currentLanguage]}
                        </div>
                        <div id="contact-box" className="contact-1">
                            <div className="about-text">
                                <ReactGA.OutboundLink
                                    eventLabel="Contact -> Email Press"
                                    to={mailPress[this.props.currentLanguage]}
                                    target="_blank"
                                >
                                    press@aozame.com
                                </ReactGA.OutboundLink>
                            </div>
                        </div>
                        <div className="subtitle">
                            {subtitle3[this.props.currentLanguage]}
                        </div>
                        <div id="contact-box" className="contact-2">
                            <div className="about-text">
                                <ReactGA.OutboundLink
                                    eventLabel="Contact -> Email Help"
                                    to={mailHelp[this.props.currentLanguage]}
                                    target="_blank"
                                >
                                    help@aozame.com
                                </ReactGA.OutboundLink>
                            </div>
                        </div>  
                    </div>
                </div>
                <Footer
                    currentLanguage = {this.props.currentLanguage}
                />
            </div>
        );
    }
}

class ContactSocial extends Component{
    constructor(props){
        super(props);
        this.state = {}
    }

    render() {
        const linkIcon = require('./components/img/social/' + this.props.type +'.png')
        const analyticsEventLabel = "Contact -> " + this.props.link
        return (
                <ReactGA.OutboundLink
                    eventLabel={analyticsEventLabel}
                    to={this.props.link}
                    target="_blank"
                >
                    <img src={linkIcon} width="80px" alt="link"/>
                </ReactGA.OutboundLink>
        );
    }
}

class Navigation extends Component{
    constructor(props){
        super(props);
        this.state = {}
    }

    render() {
        let stringEn = {1: "overview",
                        2: "facebook",
                        3: "help"};
        let stringJp = {1: "ゲーム概要",
                        2: "facebook",
                        3: "サッポート"};  
        var navItems = [];
        for (var i = 1; i < Object.keys(stringEn).length+1; i++) {
            if (this.props.language === "en"){
                navItems.push(
                    <NavItem
                    key = {"nav"+i}
                    string = {stringEn[i]}
                    icon = {i}
                    setSection = {this.props.setSection}
                    />
                )
            } else {
                navItems.push(
                    <NavItem
                    key = {"nav"+i}
                    string = {stringJp[i]}
                    icon = {i}
                    setSection = {this.props.setSection}
                    />
                )
            }
        }
        return (
            <div id="navigation">
                {navItems}
            </div>
        );
    }
}

class NavItem extends Component{
    constructor(props){
        super(props);
        this.state = {}
        this.setSection = this.setSection.bind(this);
    }

    setSection(){
        this.props.setSection(this.props.icon);
    }


    render() {
        const navIcon = require('./components/img/navicons/' + this.props.icon + '.png')
        const navClass = "nav-circle nav-" + this.props.icon;
        return (
            <div className={navClass}>
                <button onClick = {this.setSection}>
                    <div className="nav-icon"><img src={navIcon} className="icon" alt="icon"/></div>
                    <div className="nav-title">{this.props.string}</div>
                </button>
            </div>
        );
    }
}

class CountryButton extends Component{
    constructor(props){
        super(props);
        this.state = {}
        this.setCountry = this.setCountry.bind(this);
    }

    setCountry(){
        if (this.props.currentLanguage === "en"){
            this.props.setLanguage("jp");
        } else {
            this.props.setLanguage("en");
        }
    }

    render() {
        let languageString;
        if (this.props.currentLanguage === "en"){
            languageString = "日本語";
        } else {
            languageString = "English";
        }
        return (
            <button id="country-button" onClick = {this.setCountry}>
                <div id="language-toggle">
                    {languageString}
                </div>
            </button>
        );
    }
}

export default site;
